<template>
	<div>
		<div class="crumbs">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>
					<i class="el-icon-lx-cascades"></i> 分类管理
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="item">
			<el-row :gutter="20">
				<el-col :span="6">
					<div class="container">
						<el-row>
							<el-col :span="6" align="center">
								<el-button type="primary" @click="addUser">新增</el-button>
							</el-col>
						</el-row>
						<div class="sidebar">
							<el-input style="margin: 5px 0;" placeholder="搜索" v-model="filterText">
							</el-input>
							<el-tree :data="list" default-expand-all node-key="id" ref="tree" highlight-current
								@current-change="handleNodeClick" :filter-node-method="filterNode"
								:props="defaultProps">
								<template #default="{ node }">
									<span class="custom-tree-node">
										<span>{{ node.label }}</span>
									</span>
								</template>
							</el-tree>
						</div>
					</div>

				</el-col>
				<el-col :span="18">
					<!-- <addClass v-if="type==1"/>
                    <editClass  v-if="type==2"/> -->
					<detailClass @changeType="changeType" @getList="getList" :currentItem="currentItem" :type="type"
						v-if="type!=0" />
				</el-col>
			</el-row>
		</div>
		<el-dialog title="添加分类" v-model="dialogVisible" width="80%" :close-on-click-modal="false">
			<addClass :list="list" @changeType="changeType" @getList="getList" @close="close" v-if="dialogVisible"/>
		</el-dialog>
	</div>
</template>

<script>
	import addClass from "./addClass";
	// import editClass from "./editClass";
	import detailClass from "./detailClass";
	export default {
		components: {
			addClass,
			// editClass,
			detailClass
		},
		data() {
			return {
				list: [],
				pid: 0,
				defaultProps: {
					children: 'lists',
					label: 'name',
					value: 'pid'
				},
				filterText: '',
				expanded_keys: [0], //默认展开key
				type: 0, // 0初始状态 1新增 2编辑 3详情 
				currentItem: '',
				dialogVisible: false
			}
		},
		created() {
			this.getList()
		},
		watch: {
			filterText(val) {
				this.$refs.tree.filter(val);
			}
		},
		methods: {
			changeType(type) {
				this.type = type
			},
			handleNodeClick(data) {
				// console.log(data)
				this.currentItem = data
				this.type = 3
				// console.log(this.currentId)
			},
			filterNode(value, data) {
				console.log(data)
				if (!value) return true;
				return data.name.indexOf(value) !== -1;
			},
			getList() {
				this.$axios({
					url: 'admin/category/getList',
					method: 'get',
				}).then(({
					code,
					data
				}) => {
					if (code == 200) {
						console.log(data)
						this.list = data
					} else {
						//this.$message.error(res.msg)
					}
				}).catch(err => {
					console.log(err)
				});
			},
			addUser() {
				// console.log(this.currentItem)
				// if(this.currentItem.is_end) {
				//     this.$message("终极分类无法添加子分类")
				// }else {
				//     this.type =1
				//     this.currentItem = {
				//         pid : this.currentItem.id
				//     }
				// }
				this.dialogVisible = true
			},
			close() {
				this.dialogVisible = false
			}
		}
	}
</script>

<style scoped>
	.sidebar>>>.is-current .el-tree-node__content {
		background: #409EFF;
	}

	.sidebar>>>.is-current .el-tree-node__content .el-tree-node__label {
		color: #fff;
	}
</style>
