<template>
	<div class="container">
		<el-form ref="form" :model="form" :label-position="labelPosition">
			<el-form-item label="父级分类" prop="name">
				<el-cascader :options="options_list" :props="defaultProps" @change="idChange" :show-all-levels="false"></el-cascader>
			</el-form-item>
			<el-form-item label="分类名称" prop="name">
				<el-input v-model="form.name" placeholder="请输入分类名称"></el-input>
			</el-form-item>
			<el-form-item label="序号" prop="order">
				<el-input v-model="form.order" placeholder="请输入序号"></el-input>
			</el-form-item>
			<el-form-item label="是否为终极分类">
				<el-select v-model="form.is_end" placeholder="请选择">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="是否必须提供证书">
				<el-select v-model="form.is_credential" placeholder="请选择">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</el-form-item>
			<el-button @click="save">保存</el-button>
		</el-form>
	</div>
</template>

<script>
	export default {
		props: ['list'],
		data() {
			return {
				labelPosition: 'top',
				form: {
					name: '',
					pid: 0,
					is_end: '',
					is_credential: '',
					order: 0
				},
				options: [{
						label: '是',
						value: 1
					},
					{
						label: '否',
						value: 0
					}
				],
				defaultProps: {
					checkStrictly: true,
					children: 'lists',
					label: 'name',
					value: 'id'
				},
			}
		},
		created() {
			this.options_list=[
				{
					name:'顶级分类',
					id: 0,
					lists: this.list
				}
			]
		},
		methods: {
			idChange(val) {
				this.form.pid = val[3]||val[2]||val[1]||val[0]||0
				// console.log(val)
				// console.log(this.form.pid)
			},
			save() {
				this.$axios({
					url: 'admin/category/create',
					method: 'post',
					data: {
						...this.form
					},
				}).then(({
					code,
					msg
				}) => {
					if (code == 200) {
						this.$message.success(msg)
						this.$emit("changeType",0)
						this.$emit('getList')
						this.$emit('close')
					} else {
						//this.$message.error(res.msg)
					}
				}).catch(err => {
					console.log(err)
				});
			}
		}
	}
</script>

<style scoped>

</style>
