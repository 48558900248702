<template>
	<div class="container" v-loading="loading">
		<p>{{type==1?'添加分类':type==2?'编辑分类':'分类详情'}}</p>
		<el-form ref="form" :model="form" :label-position="labelPosition">
			<el-form-item label="分类名称" prop="name">
				<el-input v-model="form.name" placeholder="请输入分类名称" :disabled="type==3?true:false"></el-input>
			</el-form-item>
			<el-form-item label="序号" prop="order">
				<el-input v-model="form.order" placeholder="请输入序号" :disabled="type==3?true:false"></el-input>
			</el-form-item>
			<el-form-item label="是否为终极分类">
				<el-select v-model="form.is_end" placeholder="请选择" :disabled="type==3?true:false">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="是否必须提供证书">
				<el-select v-model="form.is_credential" placeholder="请选择" :disabled="type==3?true:false">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</el-form-item>
			<el-button @click="save" v-if="type==1">新增</el-button>
			<el-button @click="save" v-if="type==2">保存</el-button>
			<el-button @click="changeType" v-if="type==3">编辑</el-button>
			<el-button @click="changeStatus(0)" v-if="type==3&&form.status==1">下架</el-button>
			<el-button @click="changeStatus(1)" v-if="type==3&&form.status==0">发布</el-button>
			<el-button @click="changeStatus(1)" v-if="type==3&&form.status==2">上架</el-button>
			<el-button @click="del" v-if="type==3&&form.status==0">删除</el-button>
		</el-form>
	</div>
</template>

<script>
	export default {
		props: ['currentItem', 'type'],
		data() {
			return {
				labelPosition: 'top',
				form: {
					name: '',
					pid: 0,
					is_end: '',
					is_credential: '',
					order: 0,
					status: ''
				},
				options: [{
						label: '是',
						value: 1
					},
					{
						label: '否',
						value: 0
					}
				],
				loading: false
			}
		},
		watch: {
			currentItem(val) {
				if (val.id) {
					this.getDetail(val.id)
				} else {
					this.form = {
						name: '',
						pid: val.pid,
						is_end: '',
						is_credential: '',
						order: 0,
						status: ''
					}
				}

			}
		},
		created() {
			console.log(this.currentItem.id)
			if (this.currentItem) {
				// let {
				//     name,
				//     pid,
				//     is_end,
				//     is_credential,
				//     order,
				//     id,
				//     status
				// } = this.currentItem
				// this.form = {
				//     name,
				//     pid,
				//     is_end,
				//     is_credential,
				//     order,
				//     id,
				//     status
				// }
				this.getDetail(this.currentItem.id)
			}

			// this.getDetail()
			// this.form = this.currentItem
		},
		methods: {
			save() {
				let url = 'admin/category/create'
				if (this.form.id) {
					url = 'admin/category/update'
				}
				this.$axios({
					url,
					method: 'post',
					data: {
						...this.form
					},
				}).then(({
					code,
					msg
				}) => {
					if (code == 200) {
						// console.log(data)
						this.$message.success(msg)
						this.$emit("changeType", 3)
						this.$emit('getList')
					}
				}).catch(err => {
					console.log(err)
				});
			},
			getDetail(id) {
				if (this.loading) return
				this.loading = true
				this.$axios({
					url: 'admin/category/getDetail',
					method: 'get',
					params: {
						id
					},
				}).then(({
					code,
					data
				}) => {
					this.loading = false
					if (code == 200) {
						console.log(data)
						this.form = data
					} else {
						//this.$message.error(res.msg)
					}
				}).catch(err => {
					console.log(err)
				});
			},
			changeType() {
				this.$emit("changeType", 2)
			},
			changeStatus(val) {
				let url = "admin/category/offshelf"
				if (val) url = "admin/category/onshelf"
				this.$axios({
					url,
					method: 'get',
					params: {
						id: this.form.id
					},
				}).then(({
					code,
					msg
				}) => {
					if (code == 200) {
						this.$emit('getList')
						this.$message.success(msg)
						this.getDetail(this.form.id)
					} else {
						//this.$message.error(res.msg)
					}
				}).catch(err => {
					console.log(err)
				});
			},
			del() {
				this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning',
					})
					.then(() => {
						this.$axios({
							url:'admin/category/del',
							method: 'DELETE',
							params: {
								id: this.form.id
							},
						}).then(({
							code,
							msg
						}) => {
							if (code == 200) {
								this.$message.success(msg)
								this.$emit('getList')
								this.$emit("changeType",0)
							} else {
								//this.$message.error(res.msg)
							}
						}).catch(err => {
							console.log(err)
						});
					})
					.catch(() => {
						
					})
			}
		}
	}
</script>

<style scoped>

</style>
